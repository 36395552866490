<template>
  <div
    ref="sectionClientsRef"
    class="relative flex h-screen min-h-[820px] items-center justify-center overflow-hidden text-white transition-colors duration-500 ease-linear"
    :class="data.background === 'Black' ? 'bg-neutral-800' : 'bg-noiseGradientGreen'"
  >
    <div v-if="data.background === 'Black'" class="absolute right-0 max-w-2xl">
      <img
        loading="lazy"
        :src="moonBlack"
        class="object-cover"
        :width="1600"
        :height="1600"
        alt="Moon"
      />
    </div>
    <div
      v-else
      class="w-[calc(100%+50vw) absolute -bottom-[50vh] -left-52 top-auto h-full scale-[1.4] lg:bottom-auto 2xl:-top-20"
    >
      <img
        loading="lazy"
        :src="moon"
        class="object-cover"
        :width="1600"
        :height="1600"
        alt="Moon"
      />
    </div>

    <div ref="stage" class="absolute z-[3] h-full min-h-screen w-full">
      <div
        v-for="client in data.clientList.clients"
        ref="words"
        :key="client.id"
        class="word absolute w-fit text-center italic"
      >
        {{ client.client }}
      </div>
    </div>

    <div
      v-if="data.background !== 'Black' && isDesktop"
      class="absolute hidden h-full w-full lg:block"
    >
      <img
        loading="lazy"
        class="absolute -left-1/3 bottom-0 z-2 lg:-bottom-1/2 2xl:-bottom-3/4"
        :src="rocketBackground"
        alt="STI Rocket"
        width="2600"
        height="1300"
      />
    </div>
    <div v-if="data.background !== 'Black' && !isDesktop" class="absolute h-full w-full lg:hidden">
      <img
        loading="lazy"
        class="absolute -left-1/3 bottom-0 z-2 sm:-bottom-1/3"
        :src="rocketMobileBg"
        alt="STI Rocket"
        width="650"
        height="1300"
      />
    </div>

    <div
      v-if="data.title"
      ref="headingRef"
      class="clients-heading container relative z-10 scale-0 text-center text-5xl-ub text-white"
      v-html="data.title"
    />
  </div>
</template>

<script setup lang="ts">
// import EarthManager from './renderEarth'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import type { SectionClientListRecord } from '~/types/generated'
import moon from '~/assets/images/clients/moon.webp'
import moonBlack from '~/assets/images/clients/moon-black.webp'
import rocketBackground from '~/assets/images/backgrounds/rocket.webp'
import rocketMobileBg from '~/assets/images/backgrounds/rocket-mobile.webp'
import { theme } from '#tailwind-config'

const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')

const { isDesktop } = useDevice()

const props = defineProps({
  data: {
    type: Object as PropType<SectionClientListRecord>,
    default: () => {}
  }
})

const isMounted = ref(false)
// let sceneManager: any | undefined = null
const stage: Ref<HTMLDivElement | null> = ref(null)
const words: Ref<HTMLElement[] | null> = ref(null)

const sectionClientsRef = ref<HTMLElement | null>(null)
const headingRef = ref<HTMLElement | null>(null)
let tween: null | any = null

const isInViewport = ref(false)

const { stop } = useIntersectionObserver(
  sectionClientsRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isInViewport.value = true
      animateWords()
    } else {
      isInViewport.value = false
      words.value?.forEach(word => {
        word.style.animation = ''
      })
    }
  },
  {
    threshold: 0.1
  }
)

const wordTopOffset = ref(0)

function animateWords() {
  const animationDuration = isLG.value ? 21000 : 16000 // Adjust as needed
  words.value?.forEach((word, index) => {
    if (stage.value?.offsetHeight && stage.value?.offsetWidth) {
      const delay = isLG.value
        ? index * 0.1 * (animationDuration / 6) +
          Math.floor(Math.random() * (4000 - 1000 + 1) + 1000)
        : index * 0.6 * (animationDuration / 16) + Math.floor(Math.random() * (500 - 200 + 1) + 200)
      const element = word as HTMLElement

      // Set initial left position
      element.style.left = '0px'
      element.style.animation = `initialFadeInTranslate ${animationDuration}ms linear ${delay}ms forwards infinite`
      element.style.fontSize = `${props.data.clientList.clients[index].importance}px`

      element.style.top = `${wordTopOffset.value}px`

      wordTopOffset.value += element.clientHeight

      if (wordTopOffset.value > stage.value?.offsetHeight) {
        wordTopOffset.value = 0
      }

      useTimeoutFn(() => {
        if (isLG.value) {
          element.style.animation = `fadeInTranslate ${animationDuration * 2}ms linear ${delay}ms forwards infinite`
        } else {
          element.style.animation = `fadeInTranslateMobile ${animationDuration * 4}ms linear ${delay / 4}ms forwards infinite`
        }
      }, delay + animationDuration)
    }
  })
}

onMounted(() => {
  useTimeoutFn(() => {
    isMounted.value = true
  }, 500)

  if (sectionClientsRef.value && headingRef.value) {
    gsap.registerPlugin(ScrollTrigger)

    tween = gsap.timeline({
      scrollTrigger: {
        trigger: sectionClientsRef.value,
        start: 'top top',
        markers: false
      }
    })

    tween.to(headingRef.value, {
      opacity: 1,
      scale: 1,
      duration: 3
    })
    tween.to(
      headingRef.value,
      {
        opacity: 0,
        duration: 1
      },
      '-=1'
    )
    ScrollTrigger.refresh()
  }
})

onUnmounted(() => {
  tween?.kill()
  stop()
})
</script>

<style lang="postcss">
.clients-heading mark {
  color: white;
}

.word {
  position: absolute;
  transform: scale(0);
  font-weight: bold;
  -webkit-text-stroke: 1px white;
  color: transparent;
}

@keyframes initialFadeInTranslate {
  0% {
    transform: translateX(-100%); /* Start off-screen on the left */
  }
  100% {
    transform: translateX(100vw); /* Move off-screen on the right */
  }
}

@keyframes fadeInTranslate {
  0% {
    left: -40vw;
    transform: translateX(-100%); /* Start off-screen on the left */
  }
  100% {
    left: 0;
    transform: translateX(100vw); /* Move off-screen on the right */
  }
}

@keyframes fadeInTranslateMobile {
  0% {
    left: -400vw;
    transform: translateX(-100%); /* Start off-screen on the left */
  }
  100% {
    left: 0;
    transform: translateX(100vw); /* Move off-screen on the right */
  }
}
</style>
